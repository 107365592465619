<template>
  <Popup
    title="주문상세"
    :maxWidth="800"
    closeBtnText="닫기"
    @onClickClose="viewModel.onClickSettleOrderDetailPopupClose()">
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col />
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">주문정보</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>주문번호</th>
          <td>{{ viewModel.model.order_data.order_number }}</td>
        </tr>
        <tr>
          <th>상품명</th>
          <td>{{ viewModel.model.order_data.product_name }}</td>
        </tr>
        <tr>
          <th>결제수단</th>
          <td>{{ viewModel.model.order_data.pay_method }}</td>
        </tr>
        <tr>
          <th>주문상태</th>
          <td>{{ viewModel.model.order_data.order_status }}</td>
        </tr>
        <tr>
          <th>환불가능금액</th>
          <td :class="{tc_red : viewModel.model.order_data.can_refund_price < 0}">{{ viewModel.model.order_data.can_refund_price | convertNumberToComma }} 원</td>
        </tr>
      </template>
    </TableView>
    <Board
      :boardData="viewModel.boardData.ORDER.boardData"
      :searchParams.sync="viewModel.boardData.ORDER.searchParams"
      :dataList="viewModel.model.order_data.trade_list"
      :paginationData="viewModel.boardData.ORDER.paginationData"/>
  </Popup>
</template>

<script>
import Popup from '@lemontree-ai/lemontree-admin-common-front/components/layout/popup/Popup';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';

export default {
  name:'SettleOrderDetailPopup',
  components:{
    Popup,
    TableView,
    Board,
  },
  props:{
    viewModel: Object,
  },
}
</script>