import SettleModel from '@/views/store/settle/model/SettleModel'
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import { addComma } from '@lemontree-ai/lemontree-admin-common-front/utils/stringUtils'

export default class SettleListViewModel {
  constructor() {
    this.model = new SettleModel();
    this.isModify = false;
    this.boardData = {
      HISTORY:{
        boardData : {
          title:'거래내역',
          drawDataList: [
            {
              title:'승인일',
              width:'100px',
              value:'approval_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              }
            },
            {
              title:'거래ID',
              width:'120px',
              value:'trade_id',
              isAlignLeft: true
            },
            {
              title:'거래구분',
              width:'100px',
              value:'trade_type'
            },
            {
              title:'승인금액',
              width:'100px',
              value:'payment_approval',
              filter:{
                name:'convertNumberToComma',
              },
              isAlignLeft: true
            },
            {
              title:'수수료',
              width:'100px',
              value:'fee_price',
              filter:{
                name:'convertNumberToComma',
              },
              isAlignLeft: true
            },
            {
              title:'부가세',
              width:'100px',
              value:'surtax_price',
              filter:{
                name:'convertNumberToComma',
              },
              isAlignLeft: true
            },
            {
              title:'주문번호',
              width:'',
              isAlignLeft: true,
              isEllip: true,
              value:'order_number'
            },
          ],
          option:{
            isTotal: true,
            rowIdValue: 'trade_id'
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:3,
          totalPage:3
        }
      },
      CARRY:{
        boardData : {
          title:'정산이월내역',
          drawDataList: [
            {
              title:'정산ID',
              width:'100px',
              value:'settle_id',
              isAlignLeft: true
            },
            {
              title:'정산예정일',
              width:'',
              value:'settle_date',
              valueCustom:[
                {
                  type: 'value',
                  value:'settle_date',
                  filter:{
                    name:'convertDateFormat',
                    value:'YYYY-MM-DD'
                  },
                },
                { 
                  type: 'text',
                  value: '('
                },
                {
                  type: 'value',
                  value:'settle_date',
                  filter:{
                    name:'getDayToWeek',
                  },
                },
                { 
                  type: 'text',
                  value: ')'
                },
              ],
              isAlignLeft: true
            },
            {
              title:'이월 정산금',
              width:'',
              value:'settle_price',
              filter:{
                name:'convertNumberToComma',
              },
              isAlignLeft: true
            },
            {
              title:'최종 정산금',
              width:'',
              value:'settle_price',
              filter:{
                name:'convertNumberToComma',
              },
              isAlignLeft: true
            },
            {
              title:'상태',
              width:'60px',
              value:'status',
              filter:{
                name:'convertIdToText',
                value:'settle_status'
              },
              class: { classColorFilter:{ name:'convertIdToColor', dataListName:'settle_status', prev:'tc' } },
              isAlignLeft: true
            },
            {
              title:'담당자',
              width:'72px',
              value:'settle_manager_name',
              isAlignLeft: true
            },
          ],
          option:{
            isTotal: true,
            rowIdValue: 'settle_id'
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:2,
          totalPage:2
        }
      },
      ORDER:{
        boardData : {
          title:'거래내역',
          drawDataList: [
            {
              title:'거래ID',
              width:'130px',
              value:'trade_id',
              class:{
                name:'fw_bold'
              },
              isAlignLeft: true
            },
            {
              title:'거래유형',
              width:'72px',
              value:'trade_type',
              isAlignLeft: true
            },
            {
              title:'결제일시',
              width:'180px',
              value:'trade_at',
              valueCustom:[
                {
                  type: 'value',
                  value:'trade_at',
                  filter:{
                    name:'convertDateFormat',
                    value:'YYYY-MM-DD'
                  },
                },
                { 
                  type: 'text',
                  value: '('
                },
                {
                  type: 'value',
                  value:'trade_at',
                  filter:{
                    name:'getDayToWeek',
                  },
                },
                { 
                  type: 'text',
                  value: ') '
                },
                {
                  type: 'value',
                  value:'trade_at',
                  filter:{
                    name:'convertDateFormat',
                    value:'HH:mm:ss'
                  },
                },
              ],
              isAlignLeft: true
            },
            {
              title:'이월 정산금',
              width:'100px',
              value:'payment_price',
              filter:{
                name:'convertNumberToComma',
              },
              valueCustom:[
                {
                  type: 'value',
                  value:'payment_price',
                  filter:{
                    name:'convertNumberToComma'
                  },
                },
                { 
                  type: 'text',
                  value: ' 원'
                },
              ],
              class: { 
                classNameConvert:(value,rowData)=>{
                  return this.isMinus(value,rowData)
                }
               },
              isAlignLeft: true
            },
            {
              title:'메모',
              width:'',
              value:'trade_memo',
              isAlignLeft: true
            },
          ],
          option:{
            isTotal: true,
            isSize: true,
            rowIdValue: 'trade_id'
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:3,
          totalPage:1
        }
      },
    }
    this.isSettleOrderPopup = false;
  }
  init(id, step){
    this.getData(id, step)
  }
  onClickRow(rowId,rowData){
    router.push({ name: 'STORE_SETTLE_DETAIL', params: { id: rowId, type : this.tabData.value } });
  }
  onClickCarry(){
    const carryOk = () => {
      store.dispatch('commonToast/fetchToastStart', `이월처리가 완료되었습니다`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>이월 처리할까요?</strong><ul><li>최종정산 마이너스 금액 <strong>${addComma(this.model.settle_data.total_price*-1)}원</strong>을 해당 가맹점으로부터 반환받지 않고 다음 정산으로 이월합니다. (상계)</li><li>가맹점주들이 이용하는 시스템에도 이월로 표시됩니다.</li><li>마킹 후에는 정산완료 목록에서 열람할 수 있습니다.</li></ul>`,
      closeBtnText: '취소',
      compeleteBtnText: '네, 이월합니다',
      confirmCallBack: carryOk,
    });
  }
  onClickReturned(){
    const returnOk = () => {
      store.dispatch('commonToast/fetchToastStart', `반환완료 처리가 완료되었습니다`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>반환완료 처리할까요?</strong><ul><li>마이너스 금액 <strong>${addComma(this.model.settle_data.total_price*-1)}원</strong>을 가맹점으로부터 반환받았다면, 정산완료로 마킹할 수 있습니다.</li><li>가맹점주들이 이용하는 시스템에도 반환완료로 표시됩니다.</li><li>마킹 후에는 정산완료 목록에서 열람할 수 있습니다.</li></ul>`,
      closeBtnText: '취소',
      compeleteBtnText: '네, 반환확인 했습니다',
      confirmCallBack: returnOk,
    });
  }
  onClickReceived(){
    const receiveOk = () => {
      store.dispatch('commonToast/fetchToastStart', `입금완료 처리가 완료되었습니다`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>입금완료 처리할까요?</strong><ul><li>최종 정산금액 <strong>${addComma(this.model.settle_data.total_price)}원</strong>을 지정된 정산 계좌로 입금완료했다면, 정산완료로 마킹할 수 있습니다.</li><li>가맹점주들이 이용하는 시스템에도 입금완료로 표시됩니다.</li><li>마킹 후에는 정산완료 목록에서 열람할 수 있습니다.</li></ul>`,
      closeBtnText: '취소',
      compeleteBtnText: '네, 입금완료 처리합니다',
      confirmCallBack: receiveOk,
    });
  }
  onClickHistoryRow(rowId,rowData){
    this.model.setOrderData(rowData);
    this.isSettleOrderPopup = true;
  }
  onClickCarryRow(rowId,rowData){
    router.push({ name: 'STORE_SETTLE_DETAIL', params: { id: rowData.settle_id, step : rowData.settled_step }});
  }
  onClickSettleOrderDetailPopupClose(){
    this.model.setOrderData({});
    this.isSettleOrderPopup = false;
  }
  isMinus(value,rowData){
    return rowData.payment_price < 0 ? 'tc_red fw_bold' : 'tc_blue fw_bold';
  }
  getData(id, step){
    const resultDataList = {
      REQUEST:[
        { 
          settle_id:'GLO_1',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-31T00:00:00',
          totzlize_end_at:'2022-06-07T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'111250',
          status:'unsettled',
          delay:'',
          settled_type:'',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
        { 
          settle_id:'MEG_1',
          settle_date:'2022-06-07T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-31T24:00:00',
          settle_cycle_type:'MONTH',
          store_code:'9ESB7T',
          store_name:'메가스터디교육(주)',
          settle_price:'-3473',
          status:'unsettled',
          delay:'',
          settled_type:'',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'21097',
            refund_approval:'-25000',
            fee_price:'391',
            surtax_price:'39',
            total_price:'-3473',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
        { 
          settle_id:'GLO_2',
          settle_date:'2022-06-07T10:00:00',
          totzlize_start_at:'2022-06-01T00:00:00',
          totzlize_end_at:'2022-06-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'-13300',
          status:'unsettled',
          delay:'',
          settled_type:'',
          settle_manager_name:'',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'-40000',
            payment_approval:'30000',
            refund_approval:'0',
            fee_price:'-3000',
            surtax_price:'-300',
            total_price:'-13300',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ],
          carry_list:[
            {
              settle_id:'GLO_2',
              settle_date:'2022-06-06T10:00:00',
              totzlize_start_at:'2022-05-01T00:00:00',
              totzlize_end_at:'2022-05-08T24:00:00',
              settle_cycle_type:'WEEK',
              store_code:'3TDHTB',
              store_name:'(주)GLORANG',
              settle_price:'-13300',
              status:'unsettled',
              delay:'',
              settled_type:'CARRY',
              settled_step:'DONE',
              settle_manager_name:'박하영',
              fee_persent: 10,
            }
          ]
        },
        { 
          settle_id:'GLO_4',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'-13300',
          status:'unsettled',
          delay:'',
          settled_type:'REFUND',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'-13300',
            payment_approval:'30000',
            refund_approval:'0',
            fee_price:'-3000',
            surtax_price:'-300',
            total_price:'-13300',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            },
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
          ],
          carry_list:[
            {
              settle_id:'GLO_3',
              settle_date:'2022-06-06T10:00:00',
              totzlize_start_at:'2022-05-01T00:00:00',
              totzlize_end_at:'2022-05-08T24:00:00',
              settle_cycle_type:'WEEK',
              store_code:'3TDHTB',
              store_name:'(주)GLORANG',
              settle_price:'-13300',
              status:'unsettled',
              delay:'',
              settled_type:'CARRY',
              settled_step:'DONE',
              settle_manager_name:'박하영',
              fee_persent: 10,
            },
          ]
        },
      ],
      PENDING:[
        { 
          settle_id:'GLO_1',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'111250',
          status:'unsettled',
          delay:'',
          settled_type:'RECEIPT',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
        { 
          settle_id:'GLO_2',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'111250',
          status:'unsettled',
          delay:'',
          settled_type:'RECEIPT',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
        { 
          settle_id:'GLO_3',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'111250',
          status:'unsettled',
          delay:'',
          settled_type:'RECEIPT',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
      ],
      DONE:[
        { 
          settle_id:'GLO_1',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'111250',
          status:'unsettled',
          delay:'',
          settled_type:'RECEIPT',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
        { 
          settle_id:'MEG_1',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-31T24:00:00',
          settle_cycle_type:'MONTH',
          store_code:'9ESB7T',
          store_name:'메가스터디교육(주)',
          settle_price:'-3473',
          status:'unsettled',
          delay:'',
          settled_type:'REFUND',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            }
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            }
          ]
        },
        { 
          settle_id:'GLO_2',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'-13300',
          status:'unsettled',
          delay:'',
          settled_type:'CARRY',
          settle_manager_name:'자동이월',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'0',
            payment_approval:'150000',
            refund_approval:'-25000',
            fee_price:'-12500',
            surtax_price:'-1250',
            total_price:'111250',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            },
            carried_settle_id:'GLO_2',
            carried_settle_step:'REQUEST',
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
          ]
        },
        { 
          settle_id:'GLO_3',
          settle_date:'2022-06-06T10:00:00',
          totzlize_start_at:'2022-05-01T00:00:00',
          totzlize_end_at:'2022-05-08T24:00:00',
          settle_cycle_type:'WEEK',
          store_code:'3TDHTB',
          store_name:'(주)GLORANG',
          settle_price:'-13300',
          status:'unsettled',
          delay:'',
          settled_type:'CARRY',
          settle_manager_name:'박하영',
          fee_persent: 10,
          settle_data:{
            minus_carray_price:'-40000',
            payment_approval:'30000',
            refund_approval:'0',
            fee_price:'-3000',
            surtax_price:'-300',
            total_price:'-13300',
            account_data:{
              bank_name:'신한은행',
              account_number:'901-123-12455',
              depositor_name:'황태일',
            },
            carried_settle_id:'GLO_4',
            carried_settle_step:'REQUEST'
          },
          history_list:[
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'123963874963',
              trade_type:'결제승인',
              payment_approval:'11097',
              fee_price:'1109',
              surtax_price:'111',
              order_number:'order_id_22.023.12_0_7',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'4588475833836',
              trade_type:'환불승인',
              payment_approval:'-25000',
              fee_price:'2500',
              surtax_price:'250',
              order_number:'order_id_22.023.12_0_6',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
            {
              approval_at:'2022-05-31T24:00:00',
              trade_id:'838593583826',
              trade_type:'결제승인',
              payment_approval:'30000',
              fee_price:'3000',
              surtax_price:'300',
              order_number:'order_id_22.023.12_0_5',
              product_name:'메가스터디 시즌패스',
              pay_method:'레몬페이 (오프라인 QR)',
              order_status:'부분환불',
              can_refund_price:'290000',
              trade_list:[
                {
                  trade_at:'2022-03-09T8:31:32',
                  trade_id:'123963874963',
                  trade_type:'결제승인',
                  payment_price:'399000',
                  trade_memo:''
                },
                {
                  trade_at:'2022-03-10T11:10:02',
                  trade_id:'309620374963',
                  trade_type:'환불',
                  payment_price:'-50000',
                  trade_memo:'패키지 일부 취소'
                },
                {
                  trade_at:'2022-03-11T12:40:22',
                  trade_id:'028470374963',
                  trade_type:'환불',
                  payment_price:'-59000',
                  trade_memo:''
                }
              ]
            },
          ]
        },
      ]
    }
    const resultData = resultDataList[step].find(item => item.settle_id === id);
    this.model.setStep(step);
    this.model.setData(resultData);
  }
  onSearch(){
    this.getData();
    // router.push({ name:'SERVICE_CARD_LIST' })
  }
}