import { convertDateFormat, getDayToWeek } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'

export default class SettleModel {
  constructor(){
    this.objRaw = {};
    this.step = '';
    this.settle_id = '';
    this.settle_date = '';
    this.totzlize_start_at = '';
    this.totzlize_end_at = '';
    this.settle_cycle_type = '';
    this.store_code = '';
    this.store_name = '';
    this.settle_price = '';
    this.status = '';
    this.delay = '';
    this.settled_type = '';
    this.settle_manager_name = '';
    this.fee_persent = '';
    this.settle_data = {};
    this.history_list = [];
    this.carry_list = [];
    this.order_data = {};
    this.receipt_delay = '';
    this.receipt_delay_reson = '';
  }
  setStep(step){
    this.step = step;
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { settle_id, settle_date, totzlize_start_at, totzlize_end_at, settle_cycle_type, store_code, store_name, settle_price, status, delay, settled_type, settle_manager_name, fee_persent, settle_data, history_list, carry_list, receipt_delay, receipt_delay_reson } = obj;
    this.settle_id = settle_id;
    this.settle_date = convertDateFormat(settle_date,'YYYY-MM-DD');
    this.settle_date_week = settle_date ? getDayToWeek(settle_date) : '';
    this.totzlize_start_at = convertDateFormat(totzlize_start_at,'YYYY-MM-DD');
    this.totzlize_start_week = totzlize_start_at ? getDayToWeek(totzlize_start_at) : '';
    this.totzlize_end_at = convertDateFormat(totzlize_end_at,'YYYY-MM-DD');
    this.totzlize_end_week = totzlize_end_at ? getDayToWeek(totzlize_end_at) : '';
    this.settle_cycle_type = settle_cycle_type;
    this.store_code = store_code;
    this.store_name = store_name;
    this.settle_price = settle_price;
    this.status = status;
    this.delay = delay;
    this.settled_type = settled_type;
    this.settle_manager_name = settle_manager_name;
    this.fee_persent = fee_persent;
    this.settle_data = settle_data;
    this.history_list = history_list;
    this.carry_list = carry_list;
    this.receipt_delay = receipt_delay;
    this.receipt_delay_reson = receipt_delay_reson;
  }
  setOrderData(orderData){
    this.order_data = orderData;
  }
}