<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">{{ viewModel.model.step | convertIdToText('settle_step') }}</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>정산 ID</th>
          <td colspan="3">{{ viewModel.model.settle_id }}</td>
        </tr>
        <tr>
          <th>상호</th>
          <td>{{ viewModel.model.store_name }}</td>
          <th>가맹코드</th>
          <td>{{ viewModel.model.store_code }}</td>
        </tr>
        <tr>
          <th>정산예정일</th>
          <td colspan="3">{{ viewModel.model.settle_date }} ({{ viewModel.model.settle_date_week }})</td>
        </tr>
        <tr>
          <th>집계기간</th>
          <td colspan="3">
            {{ viewModel.model.totzlize_start_at }} ({{ viewModel.model.totzlize_start_at | getDayToWeek }}) ~ {{ viewModel.model.totzlize_end_at }} ({{ viewModel.model.totzlize_end_week }})
          </td>
        </tr>
        <tr>
          <th>수수료</th>
          <td colspan="3">{{ viewModel.model.fee_persent }}%</td>
        </tr>
      </template>
    </TableView>
    <div class="box_settle_detail">
      <div class="body_settle_detail">
        <dl class="item_minus">
          <dt>마이너스 정산이월</dt>
          <dd>{{ viewModel.model.settle_data.minus_carray_price | convertNumberToComma }}</dd>
        </dl>
        <dl>
          <dt>결제승인</dt>
          <dd>{{ viewModel.model.settle_data.payment_approval | convertNumberToComma }}</dd>
        </dl>
        <dl :class="{item_minus : viewModel.model.settle_data.refund_approval < 0}">
          <dt>환불승인</dt>
          <dd>{{ viewModel.model.settle_data.refund_approval | convertNumberToComma }}</dd>
        </dl>
        <dl :class="{item_minus : viewModel.model.settle_data.fee_price < 0}">
          <dt>수수료</dt>
          <dd>{{ viewModel.model.settle_data.fee_price | convertNumberToComma }}</dd>
        </dl>
        <dl :class="{item_minus : viewModel.model.settle_data.surtax_price < 0}">
          <dt>부가세</dt>
          <dd>{{ viewModel.model.settle_data.surtax_price | convertNumberToComma }}</dd>
        </dl>
      </div>
      <div class="foot_settle_detail">
        <dl :class="{item_minus : viewModel.model.settle_data.total_price < 0}">
          <dt>{{ isPending ? '정산예상금액' : '최종 정산금' }}</dt>
          <dd>{{ viewModel.model.settle_data.total_price | convertNumberToComma }}</dd>
        </dl>
      </div>
      <p v-if="isMinusTotal" class="desc_info">💡 다음회차 정산예정일까지 어떤 처리도 하지 않으면, 마이너스 금액은 자동으로 다음회차 정산금에 이월됩니다</p>
    </div>
    <TableView v-if="!isMinusTotal && isRequest">
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">입금 대기/지연</strong>
        </div>
        <div class="area_right">
          <Button
            btnSize="small"
            btnStyle="secondary"
            text="사유전달"/>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>대기/지연사유선택</th>
          <td>
            <Selectbox
              :dataList="'settle_receipt_delay' | getSelectDataList"
              :value.sync="viewModel.model.receipt_delay" />
            <!-- <Textarea
              v-if="viewModel.model.receipt_delay === '0'"
              placeholder="입금 대기/지연사유 입력해주세요."
              :showLine="2"
              :value.sync="viewModel.model.receipt_delay_reson"/> -->
          </td>
        </tr>
      </template>
    </TableView>
    <Board
      v-if="viewModel.model.settle_data.minus_carray_price && viewModel.model.carry_list"
      :boardData="viewModel.boardData.CARRY.boardData"
      :searchParams.sync="viewModel.boardData.CARRY.searchParams"
      :dataList="viewModel.model.carry_list"
      :paginationData="viewModel.boardData.CARRY.paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickCarryRow(rowId,rowData)">
    </Board>
    <TableView v-if="viewModel.model.step === 'DONE'">
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">정산현황</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>정산완료일</th>
          <td>{{ viewModel.model.settle_date }}({{ viewModel.model.settle_date_week }})</td>
        </tr>
        <tr>
          <th>담당자</th>
          <td>{{ viewModel.model.settle_manager_name }}</td>
        </tr>
        <tr v-if="viewModel.model.settle_data.minus_carray_price && viewModel.model.settled_type === 'CARRY'">
          <th>다음정산에 <br>마이너스 금액 이월</th>
          <td>
            <router-link
              :to="({ name: 'STORE_SETTLE_DETAIL', params: { id: viewModel.model.settle_data.carried_settle_id, step : viewModel.model.settle_data.carried_settle_step }})"
              class="tc_link">{{ viewModel.model.settle_data.carried_settle_id }}</router-link>
          </td>
        </tr>
        <tr>
          <th>상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                badgeSize="small"
                :text="viewModel.model.settled_type | convertIdToText('settled_done_type')"
                :badgeStyle="viewModel.model.settled_type | convertIdToColor('settled_type')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView v-if="viewModel.model.settled_type === 'RECEIPT'">
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">정산계좌</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>은행명</th>
          <td>{{ viewModel.model.settle_data.account_data.bank_name }}</td>
        </tr>
        <tr>
          <th>계좌번호</th>
          <td>{{ viewModel.model.settle_data.account_data.account_number }}</td>
        </tr>
        <tr>
          <th>예금주</th>
          <td>{{ viewModel.model.settle_data.account_data.depositor_name }}</td>
        </tr>
      </template>
    </TableView>
    <Board
      :boardData="viewModel.boardData.HISTORY.boardData"
      :searchParams.sync="viewModel.boardData.HISTORY.searchParams"
      :dataList="viewModel.model.history_list"
      :paginationData="viewModel.boardData.HISTORY.paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickHistoryRow(rowId,rowData)">
    </Board>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="STORE_SETTLE_LIST"
          :routeQuery="{ step : viewModel.model.step }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="secondary"
          text="엑셀 다운로드"/>
        <template
            v-if="isMinusTotal && isRequest">
          <Button
            btnSize="medium"
            btnStyle="primary_border"
            text="다음정산이월"
            @onClickBtn="viewModel.onClickCarry()"/>
          <Button
            btnSize="medium"
            btnStyle="primary"
            text="반환완료처리"
            @onClickBtn="viewModel.onClickReturned()"/>
        </template>
        <template
            v-if="!isMinusTotal && isRequest">
          <Button
            btnSize="medium"
            btnStyle="primary"
            text="입금완료 처리"
            @onClickBtn="viewModel.onClickReceived()"/>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <SettleOrderDetailPopup
        v-if="viewModel.isSettleOrderPopup"
        :viewModel="viewModel"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Textarea from '@lemontree-ai/lemontree-admin-common-front/components/common/textarea/Textarea';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import SettleOrderDetailPopup from '@/views/store/settle/view/popup/SettleOrderDetailPopup';
// viewModel
import SettleDetailViewModel from '@/views/store/settle/viewModel/SettleDetailViewModel'

export default {
  name:'SettleDetail',
  components:{
    PageWithLayout,
    TableView,
    Badge,
    Board,
    Selectbox,
    Textarea,
    Sticky,
    Button,
    SettleOrderDetailPopup
  },
  beforeMount(){
    this.setInit()
  },
  data(){
    return{
      viewModel: new SettleDetailViewModel(),
    }
  },
  watch: {
    '$route' (newVal,oldVal) {
      if(oldVal.params.id != newVal.params.id || oldVal.params.step != newVal.params.step){
        this.setInit()
      }
    }
  },
  computed:{
    isMinusTotal(){
      return this.viewModel.model.settle_data.total_price < 0
    },
    isRequest(){
      return this.viewModel.model.step === 'REQUEST'
    },
    isPending(){
      return this.viewModel.model.step === 'PENDING'
    }
  },
  methods:{
    setInit(){
      const id = this.$route.params.id;
      const step = this.$route.params.step;
      this.viewModel.init(id, step);
      this.viewModel.isModify = this.$route.meta.isModify;
    }
  }
}
</script>
<style scoped>
.box_settle_detail{margin:40px 0}
.box_settle_detail dl{display:block;overflow:hidden;padding:8px 20px;text-align:center}
.box_settle_detail dt{display:inline-block;width:50%;max-width:170px;vertical-align:top;text-align:left}
.box_settle_detail dd{display:inline-block;width:50%;max-width:170px;text-align:right;vertical-align:top}
.box_settle_detail .item_minus dd{color:#F04848}

.body_settle_detail{padding:16px 0;border-top:1px solid #ddd;border-bottom:1px solid #ddd;font-weight:600;font-size:16px;color:#222}

.foot_settle_detail{padding:8px 0;border-bottom:1px solid #ddd;font-weight:600;font-size:18px;background-color:#3DBF6910}

.foot_settle_detail + .desc_info{margin-top:14px}

@media (prefers-color-scheme: dark) {
  .body_settle_detail{color:#fff}
  .body_settle_detail,
  .foot_settle_detail{border-color:#000}
}
</style>